<template>
    <main>
        <div id="login-form--wrapper">
            <div class="column-1">
                <div class="ui basic segment container">
                    <router-link :to="{ name: 'Home' }"><img class="logo" src="~@/assets/images/logo/1991spwg.png"/></router-link>
                    <form class="ui underlined form" :class="{loading: submitting}" action="javascript:void(0);" @submit.prevent="login">
                        <div class="ui hidden section divider"></div>
                        <h1 class="ui poppins weight-400 header" style="font-size: 3rem;">Login</h1>
                        <div class="ui hidden section divider"></div>
                        <div class="field">
                            <label class="ui grey text">Username</label>
                            <input type="email" placeholder="Email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$" v-model.trim="form.username" v-lowercase autocomplete="username" :autofocus="!$isMobile" required/>
                        </div>
                        <div class="ui hidden divider"></div>
                        <div class="field">
                            <label class="ui grey text">Password</label>
                            <input type="password" v-model.trim="form.password" autocomplete="current-password" @keyup="checkCapsLock" @focus="checkCapsLock" @mousedown="checkCapsLock" @blur="is_caps_locked = false;" required/>
                            <div class="ui red text" v-show="is_caps_locked"><i class="exclamation circle icon"></i> Caps Lock is on</div>
                        </div>
                        <div class="ui hidden divider"></div>
                        <div class="inline fields" style="justify-content: space-between;">
                            <router-link :to="{ name: 'ForgotPassword' }">Forgot Password?</router-link>
                            <button type="submit" class="ui black button">Log In</button>
                        </div>
                        <div class="ui hidden section divider"></div>
                    </form>
                </div>
            </div>
            <div class="column-2">

            </div>
        </div>
    </main>
</template>

<script>
import Role from "@/assets/constants/role";

export default {
    data() {
        let username = sessionStorage.getItem("realm") || "";

        return {
            form: {
                username,
                password: ""
            },
            submitting: false,
            route_param: {},
            is_caps_locked: false
        };
    },
    created() {
        let route_param = this.$route.params;
        if(route_param) {
            this.route_param = route_param;
        }
    },
    mounted() {
        sessionStorage.removeItem("realm");
    },
    methods: {
        login() {
            this.submitting = true;

            let args = ["users/login", this.form];
            if(this._device_id) {
                args.push({
                    headers: {
                        "x-1991-device-id": this._device_id
                    }
                });
            }

            this.$http.post(...args).then(({ data, headers, status }) => {
                if(status === 200) {
                    if(data.user && data.token) {
                        //let's check the device id from local storage for non-admin
                        let checkDeviceID = null;
                        if(Role.ADMIN === data.user.role) {
                            checkDeviceID = Promise.resolve(true);
                        } else if(!this._device_id) {
                            checkDeviceID = Promise.resolve(false);
                        } else {
                            checkDeviceID = this.$http.get(`devices/${this._device_id}`, {
                                headers: {
                                    "Authorization": `Bearer ${data.token}`,
                                    "x-session-token": data.token
                                }
                            }).then((response) => {
                                return response.data.verified;
                            }).catch((error) => {
                                console.error(error);

                                //meaning it was rejected or fake. proceed to remove from state
                                this.$store.dispatch("auth/updateDeviceID", "");
                                return false;
                            });
                        }

                        checkDeviceID.then((verified) => {
                            if(!verified) {
                                throw Error("Unverified device");
                            }

                            this.$store.dispatch("auth/login", {
                                auth_token: data.token,
                                user: data.user
                            }).then(() => {
                                //then redirect to where it should be
                                let next_route = {
                                    name: "Home"
                                };

                                if(this.route_param.to) {
                                    next_route.name = this.route_param.to.name;

                                    if(this.route_param.to.params && Object.keys(this.route_param.to.params).length) {
                                        next_route.params = this.route_param.to.params;
                                    }
                                }

                                this.$router.push(next_route);
                            }).catch((error) => {
                                this.submitting = false;

                                console.error(error);
                                this.$toasted.error("Unable to save login session.", {
                                    duration: 3000
                                });
                            });
                        }).catch((error) => {
                            //need to redirect to verify device
                            this.$router.push({
                                name: "VerifyDevice",
                                params: data
                            });
                        });
                    }
                } else if(status === 205) {
                    //need to redirect to reset password
                    this.$router.push({
                        name: "ResetPassword",
                        params: Object.assign({}, this.form, {
                            token: headers["x-session-token"]
                        })
                    });
                }
            }).catch((error) => {
                this.submitting = false;

                console.error(error);
                this.$toasted.error("Login unsuccessful.", {
                    duration: 3000
                });
            });
        },
        checkCapsLock(event) {
            try {
                this.is_caps_locked = !!event.getModifierState("CapsLock");
            } catch(error) {
            }
        }
    }
};
</script>

<style lang="scss" scoped>
main {
    #login-form--wrapper {
        display: flex;
        align-items: stretch;

        .column-1 {
            background: #fff;
            width: 38.2%;
            flex: 0 0 38.2%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            .ui.form {
                align-self: stretch;
                animation: animation-slideUp ease-out 300ms;
            }
        }

        .column-2 {
            width: 61.8%;
            flex: 0 0 61.8%;
            background: #fff url('~@/assets/images/background/login.min.jpg') no-repeat center center/cover;
        }

        .logo {
            max-width: 90%;
            width: 200px;
        }
    }

    @media only screen and (min-width: 1025px) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;

        #login-form--wrapper {
            max-width: 1024px;
            box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
        }
    }

    @media only screen and (max-width: 1024px) {
        #login-form--wrapper {
            min-height: 100vh;
        }
    }

    @media only screen and (max-width: 1023px) {
        #login-form--wrapper {
            position: relative;
            align-items: center;
            justify-content: center;

            .logo {
                margin-top: -3rem;
            }

            .column-1 {
                flex: 0 0 auto;
                width: 500px;
                max-width: 100%;
                background: none;
            }

            .column-2 {
                display: none;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        background: url('~@/assets/images/background/login-main.jpg') no-repeat center center/cover,
                    linear-gradient(to bottom, rgba(253, 253, 253, 0.4) 0%, rgba(253, 253, 253, 0.9) 100%);
        background-blend-mode: saturation;

        #login-form--wrapper {
            .column-1 {
                padding: 3rem;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        #login-form--wrapper {
            .column-1 {
                padding-top: 3rem;
                padding-bottom: 3rem;

                > .ui.segment.container {
                    width: 100% !important;
                }
            }
        }
    }
}
</style>